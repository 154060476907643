import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PortfolioLayout from '../components/portfolioLayout';

const Portfolio = () => {
    const data = useStaticQuery(graphql`
    query {
      aBefore: file(relativePath: { eq: "decks/s3.webp" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      aAfter: file(relativePath: { eq: "decks/s10.webp" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      files: allFile(
        filter: { sourceInstanceName: { eq: "portfolio" }, relativeDirectory: { eq: "decks" } },
        sort: { fields: [ name ] }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed (width: 400){
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);
  
  const beforeAfter = [
      {
        'before': data.aBefore,
        'after': data.aAfter
      }
    ];

  return (
    <PortfolioLayout 
      title='Decking'
      path='portfolio-decks'
      data={data.files.edges}
      link='/deck-contractor'
      beforeAfter={beforeAfter}
      />
)};

export default Portfolio;
